import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Home"
        description="This is the personal website of Andreas Reize"
      />
      <h1>Andreas Reize Thomaskantor</h1>
      <div style={{ maxWidth: '200px', marginBottom: '1.45rem' }}>
        <Image />
      </div>
      <h2>Hier entsteht die neue Webseite von Andreas Reize</h2>
      <Link to="/page-2/">Go to page 2</Link>
    </Layout>
  )
}

export default IndexPage
